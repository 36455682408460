import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import { getNavigationItems } from 'components/header/navigationItems'
import Icon from 'components/icon/Icon'
import LanguageSwitcher from 'components/language-switcher/LanguageSwitcher'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Language } from 'types/language'
import useWindowSize from 'hooks/useWindowSize'
import { routes } from 'utils/routes'
import { HEADER_BREAK_POINT } from './constants'
import styles from './Header.module.scss'
import { HeaderProps as Props } from './types'
import { useScrollDirection } from 'hooks/useScrollDirection'
import { startSimulationOrRegistration } from 'utils/customerFlow'
import { CtaTrackingVariant } from 'types/simulation'
import { Logo } from 'assets/svg'
import { PORTAL_URL } from 'constants/constants'
import { store } from 'store/index'
import { updateMarketingData } from 'store/marketing/slice'
import { MarketingDataKeys } from 'store/marketing/types'

const Header: FC<Props> = ({ localized, toggleHeaderOnScroll }) => {
  // Scroll direction
  const scrollDirection = useScrollDirection({ disabled: !toggleHeaderOnScroll })

  // Router
  const router = useRouter()
  const { locale } = router

  // i18n
  const { t } = useTranslation(['header', 'common'])

  // Window Size
  const { isSmaller } = useWindowSize(HEADER_BREAK_POINT)
  const isDesktop = !isSmaller

  // Local state
  const [menuOpen, setMenuOpen] = useState(false)
  const [openDropdownItems, setOpenDropdownItems] = useState<string[]>([])

  // Constants
  const navigationItems = useMemo(() => getNavigationItems({ language: locale || 'nl', t }), [locale, t])

  /**
   * Close menu on route change
   */
  useEffect(() => {
    const handleRouteChange = () => setMenuOpen(false)

    // close menu on route change
    router.events.on('routeChangeComplete', handleRouteChange)

    // unsubscribe from events
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  /**
   * Fixes bug when you switch from mobile to desktop where all the dropdown menu's would be open
   */
  useEffect(() => {
    if (isDesktop) {
      setOpenDropdownItems([])
      setMenuOpen(false)
    }
  }, [isDesktop, navigationItems])

  /**
   * Prevents scrolling when mobile menu is open
   */
  useEffect(() => {
    if (menuOpen) {
      // set dropdowns open when menu is open
      setOpenDropdownItems(navigationItems.filter((item) => !!item.subItems?.length).map((item) => item.id))

      document.body.classList.add('no-scroll')

      return () => document.body.classList.remove('no-scroll')
    }
  }, [menuOpen])

  /**
   * Checks if the current pathname is active or not
   *
   * @param {string[]} routes
   * @returns boolean
   */
  const checkCurrentPathname = (routes: string[]): boolean => {
    if (!routes.length) return false

    return routes.length === 1 ? router.pathname.includes(routes[0]) : routes.some((route) => route === router.pathname)
  }

  /**
   * Returns the header links
   */
  const getNavigationLinks = () => (
    <ul>
      {navigationItems.map((item, idx) => {
        // check if navigation item is a dropdown or not
        const hasSubItems = !!item.subItems?.length

        return !item.showOnLanguage || item.showOnLanguage === locale ? (
          <li
            key={item.id}
            className={styles['dropdown-trigger']}
            onMouseEnter={() => {
              hasSubItems && isDesktop && setOpenDropdownItems([item.id])
            }}
            onMouseLeave={() => {
              hasSubItems && isDesktop && setOpenDropdownItems([])
            }}
          >
            {hasSubItems ? (
              // DROPDOWN BUTTON
              <button
                type="button"
                aria-expanded={openDropdownItems.includes(item.id)}
                aria-controls={item.id}
                className={classNames(styles['main-nav-item'], {
                  [styles.active]: checkCurrentPathname(item.isActiveRoutes),
                  [styles.highlighted]: item.hasNotification
                })}
                onClick={() =>
                  setOpenDropdownItems(
                    !openDropdownItems.includes(item.id)
                      ? [...openDropdownItems, item.id]
                      : openDropdownItems.filter((dropdownItem) => dropdownItem !== item.id)
                  )
                }
              >
                {item.text}
                <Icon name="dropdown" />
              </button>
            ) : (
              // NAVIGATION ITEM
              <Link
                href={item.destination}
                className={classNames(styles['main-nav-item'], {
                  [styles.active]: checkCurrentPathname(item.isActiveRoutes),
                  [styles.highlighted]: item.hasNotification
                })}
              >
                {item.text}
              </Link>
            )}

            {/* DROPDOWN */}
            {hasSubItems && (
              <ul id={item.id} data-open={openDropdownItems.includes(item.id)} className={classNames(styles.dropdown)}>
                {item.subItems.map((subItem) =>
                  !subItem.showOnLanguage || subItem.showOnLanguage === locale ? (
                    <li key={`${subItem.text}-${item.id}`}>
                      <Link href={subItem.destination} onClick={() => isDesktop && setOpenDropdownItems([])}>
                        {subItem.text}
                      </Link>
                    </li>
                  ) : (
                    <Fragment key={`${subItem.text}-${idx}`} />
                  )
                )}
              </ul>
            )}
          </li>
        ) : (
          <Fragment key={item.id} />
        )
      })}
    </ul>
  )

  /**
   * Handles the hamburger menu button click event
   */
  const handleHamburgerClick = () => {
    setMenuOpen(!menuOpen)
  }

  /**
   * Handles the simulation button click event
   */
  const onSimulationClick = async () => {
    if (router.pathname.includes('move')) {
      store.dispatch(updateMarketingData({ key: MarketingDataKeys.SIMULATION_SO, value: 'Move Page' }))
    }
    startSimulationOrRegistration({
      ctaTrackingVariant: CtaTrackingVariant.HEADER
    })
  }

  return (
    <header
      className={classNames([styles.header], {
        [styles['with-menu-open']]: menuOpen,
        [styles['scrolling-down']]: scrollDirection === 'down'
      })}
    >
      <nav className={classNames('container', styles.container)}>
        <div className={styles.menu}>
          <Link href={routes(locale).home} className={styles.logo} aria-label={locale === Language.FRENCH ? 'accueil' : 'home'}>
            <Logo />
          </Link>

          {!isDesktop && (
            <div className={styles['language-switcher-mobile']}>
              <LanguageSwitcher />
            </div>
          )}

          {!isDesktop && (
            <button
              className={styles['hamburger-container']}
              type="button"
              aria-label="Menu"
              aria-controls="mobile-navigation"
              aria-expanded={!!menuOpen}
              onClick={() => handleHamburgerClick()}
            >
              {!menuOpen && <div className={styles['hamburger-label']}>Menu</div>}
              <div className={classNames(styles.hamburger, { [styles.active]: menuOpen })}>
                <span className={styles.bar} />
                <span className={styles.bar} />
                <span className={styles.bar} />
              </div>
            </button>
          )}

          {isDesktop && <div className={styles.nav}>{getNavigationLinks()}</div>}
        </div>

        <div className={styles.content}>
          {!isDesktop && (
            <div id="mobile-navigation" className={styles['mobile-nav']}>
              {getNavigationLinks()}
            </div>
          )}

          {isDesktop && (
            <div className={styles['language-switcher']}>
              <LanguageSwitcher localized={localized} />
            </div>
          )}

          <div className={styles.cta}>
            <Button className={styles['cta-button']} isFullwidthMobile onClick={onSimulationClick}>
              {t('common:calculateProposal', 'Bereken jouw voorstel')}
            </Button>
            <a
              href={`${PORTAL_URL}/login?lang=${locale}`}
              aria-label="portal"
              target="_blank"
              rel="noreferrer"
              className={styles['portal-button']}
            >
              <Icon name="portal" />
              {t('myBolt', 'My Bolt')}
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
