import { FC } from 'react'
import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import { FixedPriceModalProps as Props, SalesPricesFormInputs } from 'components/simulation-card/sales-prices-section/types'
import { useForm } from 'react-hook-form'
import { SimulationType } from 'store/marketing/types'
import { useTranslation } from 'next-i18next'
import Drawer from 'components/drawer/Drawer'
import styles from '../price-simulation-section/edit-instalment-drawer/EditInstalmentDrawer.module.scss'
import { updateFixedPrices, updateInput } from 'store/customer/slice'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { InputGroupKeys, SimulationFieldKeys } from 'store/customer/enums'

const FixedPriceDrawer: FC<Props> = ({ onRequesteClose, isOpen }) => {
  // Redux store
  const { fixedPrices, inputs } = useStoreSelector((store) => store.customer)
  const { needsGas } = inputs.personalData
  const dispatch = useStoreDispatch()

  // I18N
  const { t } = useTranslation(['simulationCard', 'validation', 'common'])

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { ...fixedPrices }, mode: 'onBlur' })

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {SalesPricesFormInputs} data
   */
  const onSubmit = (data: SalesPricesFormInputs) => {
    const { electricity, gas } = data

    // Set the simulation type to fixed amount
    dispatch(
      updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE, value: SimulationType.FIXED_AMOUNT })
    )

    // Update the fixed prices
    dispatch(updateFixedPrices({ electricity, gas: gas ?? 0 }))

    // Close the modal
    onRequesteClose()
  }

  return (
    <Drawer isOpen={isOpen} onRequestClose={onRequesteClose} title={t('sales.prices.title', 'Prijzen')}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className="form-row">
          <div className="form-input">
            <label htmlFor="electricity">
              {t('common:electricity', 'Elektriciteit')} ({t('common:inclVat', 'incl. BTW')})
            </label>
            <input
              type="number"
              step={1}
              {...register('electricity', { min: needsGas ? 10 : 20, required: true })}
              className={classNames({ 'has-error': errors && errors.electricity })}
            />
            {errors && errors.electricity && (
              <p className="is-error-text">
                {errors.electricity.type === 'required' && t('validation:required')}
                {errors.electricity.type === 'min' &&
                  t('validation:min.number', {
                    attribute: t('common:electricity', 'Elektriciteit'),
                    min: needsGas ? 10 : 20
                  })}
              </p>
            )}
          </div>

          {needsGas && (
            <div className="form-input">
              <label htmlFor="gas">
                {t('common:gas', 'Gas')} ({t('common:inclVat', 'incl. BTW')})
              </label>
              <input
                type="number"
                step={1}
                {...register('gas', { min: 10, required: true })}
                className={classNames({ 'has-error': errors && errors.gas })}
              />
              {errors && errors.gas && (
                <p className="mt-200 mb-none is-red">
                  {errors.gas.type === 'required' && t('validation:required')}
                  {errors.gas.type === 'min' && t('validation:min.number', { attribute: t('common:gas', 'Gas'), min: 10 })}
                </p>
              )}
            </div>
          )}
        </div>

        <Button isFullwidth className="mt-auto">
          {t('salesPrices.save', 'Bewaar')}
        </Button>
      </form>
    </Drawer>
  )
}

export default FixedPriceDrawer
