import { FC } from 'react'
import { BannerProps as Props, PredefinedBanner } from './types'
import { PredefinedBanners } from './PredefinedBanners'
import { Trans, useTranslation } from 'next-i18next'
import { routes } from 'utils/routes'
import { useRouter } from 'next/router'
import styles from './Banner.module.scss'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { PHONE_NOT_REACHABLE } from 'constants/constants'
import { useScrollDirection } from 'hooks/useScrollDirection'
import { useStoreSelector } from 'hooks/store'
import { SIMULATION_DOWN } from 'constants/envs'

export const Banner: FC<Props> = ({ onClickHandler, disableToggleOnScroll, className }) => {
  // REDUX STORE
  const { banner, bannerType } = useStoreSelector((store) => store.marketing)

  // Next Router
  const { locale, pathname } = useRouter()

  // i18n
  const { t } = useTranslation('common')

  // Constants
  const showDefaultBanner = false // set to: pathname === '/' for banner on homepage
  const isNotMiniGenerators = pathname !== routes(locale).miniGenerators
  const showBanner = (isNotMiniGenerators && (banner || bannerType)) || showDefaultBanner || PHONE_NOT_REACHABLE || SIMULATION_DOWN

  // Scroll direction
  const scrollDirection = useScrollDirection({ disabled: disableToggleOnScroll })

  /**
   * Returns the predefined banner based on the marketing state values
   */
  const getPredefinedBanner = (): PredefinedBanner | null => {
    if (SIMULATION_DOWN) return PredefinedBanners.simulationDown

    if (bannerType) return PredefinedBanners[bannerType]

    return null
  }

  const predefinedBanner = getPredefinedBanner()
  const color = predefinedBanner && predefinedBanner.color ? predefinedBanner.color : 'yellow'

  if (showBanner) {
    return (
      <div
        className={classNames(
          styles.banner,
          'section',
          color === 'yellow' ? styles['is-yellow'] : `is-${color}`,
          {
            [styles['scrolling-down']]: scrollDirection === 'down'
          },
          className
        )}
        onClick={onClickHandler}
        role="presentation"
      >
        <div className="container">
          {predefinedBanner ? (
            <p>
              <Trans ns={predefinedBanner.ns} i18nKey={predefinedBanner.main.i18nKey}>
                {predefinedBanner.main.defaultCopy}
              </Trans>
              {predefinedBanner.small && (
                <>
                  <br />
                  <small>
                    <Trans ns={predefinedBanner.ns} i18nKey={predefinedBanner.small?.i18nKey}>
                      {predefinedBanner.small?.defaultCopy}
                    </Trans>
                  </small>
                </>
              )}
            </p>
          ) : (
            <p>
              <span>{PHONE_NOT_REACHABLE ? parse(t('phoneNotReachable')) : showDefaultBanner ? parse(t('defaultBanner')) : banner}</span>
            </p>
          )}
        </div>
      </div>
    )
  }

  return null
}

export default Banner
