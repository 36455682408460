import { useEffect, useState } from 'react'
import { ScrollDirectionProps, ScrollDirections } from 'hooks/types'

export const useScrollDirection = ({ disabled }: ScrollDirectionProps): ScrollDirections | null => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirections | null>(null)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    if (!disabled) {
      window.addEventListener('scroll', updateScrollDirection) // add event listener
    }
    return () => {
      window.removeEventListener('scroll', updateScrollDirection) // clean up
    }
  }, [disabled, scrollDirection])

  return scrollDirection
}
