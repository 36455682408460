import { FC } from 'react'
import classNames from 'classnames'
import { SUPPORTED_LANGUAGES } from 'constants/i18n'
import { Language } from 'types/language'
import { useRouter } from 'next/router'
import { translateRoute } from 'utils/routes'
import styles from './LanguageSwitcher.module.scss'
import { InputGroupKeys, PersonalDataFieldKeys } from 'store/customer/enums'
import { LanguageSwitcherProps as Props } from './types'
import { updateInput } from 'store/customer/slice'
import { useStoreDispatch } from 'hooks/store'

const LanguageSwitcher: FC<Props> = ({ localized }) => {
  // Redux store
  const dispatch = useStoreDispatch()

  // Router
  const router = useRouter()
  const { asPath, locale, pathname, query } = router

  /**
   * Changes the language to the given one
   *
   * @param {Language} newLang
   */
  const changeLanguage = (newLang: Language) => {
    // Update in the registration store
    dispatch(updateInput({ group: InputGroupKeys.PERSONAL_DATA, key: PersonalDataFieldKeys.LANGUAGE, value: newLang }))

    // Just switch the route if homepage
    if (pathname === '/') {
      return router.push(pathname, asPath, { locale: newLang })
    }

    // Translate the route first before switching if not homepage
    const { asPath: translatedAsPath } = translateRoute(router, newLang)
    return router.push({ pathname, query }, translatedAsPath, { locale: newLang })
  }

  return (
    <ul className={styles['language-switcher']}>
      {SUPPORTED_LANGUAGES.map((lang: string) => {
        return (
          <li key={lang}>
            <button
              type="button"
              className={classNames({ [styles['is-active']]: locale === lang })}
              disabled={locale === lang}
              onClick={() => {
                if (!!localized && localized[lang]) return router.push({ pathname: localized[lang] }, localized[lang], { locale: false })
                if (locale !== lang) return changeLanguage(lang as Language)
              }}
            >
              {lang.toUpperCase()}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default LanguageSwitcher
