import { getNavigationItemsProps, navigationItem } from './types'
import { routes } from 'utils/routes'
import { Language } from 'types/language'
import { EnergyGardens } from 'features/energy-gardens/types'

export const getNavigationItems = ({ language, t }: getNavigationItemsProps): navigationItem[] => [
  // PRODUCERS
  {
    destination: routes(language).producers,
    id: 'producers',
    isActiveRoutes: ['/producers', '/producer-contact'],
    subItems: [
      {
        destination: routes(language).producers,
        text: t('producersOurProducers', 'Onze opwekkers')
      },
      {
        destination: routes(language).becomeProducer,
        text: t('producersBecomeProducer', 'Word opwekker')
      }
    ],
    text: t('producersNavItem', 'Energie-opwekkers')
  },

  // PRICE
  {
    destination: routes(language).pricing,
    id: 'pricing',
    isActiveRoutes: ['/pricing'],
    text: t('pricing', 'Tarief')
  },

  // Move
  {
    destination: routes(language).move,
    id: 'move',
    isActiveRoutes: ['/move'],
    text: t('move', 'Verhuizen')
  },

  // News
  {
    destination: routes(language).blog,
    id: 'news',
    isActiveRoutes: ['/news'],
    text: t('header:news', 'Nieuws')
  },

  // MORE
  {
    destination: '/#',
    id: 'more',
    isActiveRoutes: ['/about', '/encyclopedia', '/faq', '/careers'],
    subItems: [
      // Mission
      {
        destination: routes(language).about,
        text: t('mission', 'Onze missie')
      },
      // ENCYCLOPEDIA
      {
        destination: routes(language).encyclopedia,
        showOnLanguage: Language.DUTCH,
        text: t('encyclopedia', 'Encyclopedie')
      },
      // FAQ
      {
        destination: routes(language).faq,
        text: t('faq', 'Veelgestelde vragen')
      },
      // REFERRAL
      {
        destination: routes(language).referral,
        text: t('referral', 'Overtuig een vriend')
      },
      // BRUGSE ZONNETUIN
      {
        destination: routes(language, EnergyGardens.BRUGSE_ZONNETUIN).energyGarden,
        showOnLanguage: Language.DUTCH,
        text: t('brugseZonnetuin', 'Brugse zonnetuin')
      }
    ],
    text: t('more', 'Meer')
  }
]
