export const SUPPORTED_LANGUAGES = ['nl', 'fr']

// translation namespaces used in <Layout> and other common namespaces (ns)
export const DEFAULT_NAMESPACES = [
  'common',
  'commonFormFields',
  'editInstalmentPopup',
  'footer',
  'header',
  'lookingForHelp',
  'search',
  'sections',
  'seo',
  'validation'
]
export const PRODUCERS_NAMESPACES = ['producer', 'producers', 'priceDetails']

export const SIMULATION_NAMESPACES = ['simulation', 'simulationCard']
